<template>
  <div>
    <div slot="no-body">
      <div class="">
        <div>
          <div
            class="flex flex-wrap-reverse items-center data-list-btn-container"
          >
            <div class="mb-3 mt-3">
              <vs-button
                class="mr1"
                @click="addSubscribeOffLine()"
                color="primary"
                type="filled"
                icon-pack="feather"
                icon="icon-plus"
              >
                Ajouter une souscription</vs-button
              >
            </div>
          </div>

          <div class="mt-3">
            <vs-tabs>
              <vs-tab label="Souscriptions à l'étude">
                <aab-vie-subscribe-study-subscription
                  crrModuleName="AAE"
                ></aab-vie-subscribe-study-subscription>
              </vs-tab>
              <vs-tab label="Emissions contrats">
                <aab-vie-subscribe-contract-issue
                  crrModuleName="AAE"
                ></aab-vie-subscribe-contract-issue>
              </vs-tab>
              <vs-tab label="Contrats actifs">
                <aab-vie-subscribe-active-contrat
                  crrModuleName="AAE"
                ></aab-vie-subscribe-active-contrat>
              </vs-tab>
              <vs-tab label="Contrats bientôt inactifs">
                <aab-vie-subscribe-contracts-soon-inactive
                  crrModuleName="AAE"
                ></aab-vie-subscribe-contracts-soon-inactive>
              </vs-tab>
              <vs-tab label="Contrats inactifs">
                <aab-vie-subscribe-inactive-contracts
                  crrModuleName="AAE"
                ></aab-vie-subscribe-inactive-contracts>
              </vs-tab>
              <vs-tab label="Contrats à terme">
                <aab-vie-subscribe-term-contrat
                  crrModuleName="AAE"
                ></aab-vie-subscribe-term-contrat>
              </vs-tab>
              <vs-tab label="Souscriptions rejetées">
                <aab-vie-subscribe-rejected-subscription
                  crrModuleName="AAE"
                ></aab-vie-subscribe-rejected-subscription>
              </vs-tab>
            </vs-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import role from "@/config/role";
import global from "@/config/global";

export default {
  data() {
    return {
      productId: "",
      productName: "",
      isOpen: false,
      global: {},
    };
  },

  created() {
    this.global = global;
  },
  mounted() {
    if (
      this.user.type != "super_administrator_sys" &&
      !this.user.person.userAcces.chk_subscription
    ) {
      if (!this.checkUserRole("subscription").all)
        this.$router.push({ name: "Home" });
    }
    this.limit = this.rowsTable[1];
  },
  computed: {
    ...mapGetters("general", ["rowsTable", "allProduct", "brokerOwn"]),
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    addSubscribeOffLine() {
      let url = `${this.global.tunnelHost}/landing/aae?type=${this.brokerOwn.type}&id=${this.brokerOwn.id}&productID=AAE&fromOrigin=admin`;
      window.open(url, "_blank");
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    closePopup(value) {
      this.isOpen = value;
      console.log("fermeture avec succes");
    },
    OpenPupop() {
      let crrId = [];
      this.allProduct.forEach((el) => {
        crrId.push({
          id: el.id,
          label: el.label,
        });
      });
      //this.allProduct.forEach
      this.productId = "AAE";
      this.productName = "Atlantique Avenir Enfant";
      this.isOpen = true;
    },
  },
};
</script>
